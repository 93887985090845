@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIOOaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIMeaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIOuaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIO-aBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofINeaB.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIOOaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIMeaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIOuaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIO-aBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofINeaB.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIOOaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIMeaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIOuaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIO-aBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofINeaB.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIOOaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIMeaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIOuaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIO-aBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofINeaB.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIOOaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIMeaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIOuaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIO-aBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofINeaB.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIOOaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIMeaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIOuaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIO-aBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofINeaB.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIOOaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIMeaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIOuaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIO-aBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofINeaB.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIOOaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIMeaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIOuaBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofIO-aBXso.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v23/XRXV3I6Li01BKofINeaB.woff2) format('woff2');
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}



* { 
  font-family: Nunito, Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}