@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'font.css';



@layer base {
  body {
    @apply m-0 min-h-screen;
  }
  
  * {
    @apply select-none;
  }
}

@layer components {
  .circle-btn {
    @apply w-12 h-12 rounded-full shadow-askhow-01 text-center bg-askhow-white;
  }
  .circle-btn > img {
    @apply w-6 h-6 inline-block;
  }
  .big-primary-btn {
    @apply py-3 px-6 text-body-2 rounded-5 bg-askhow-primary-500 text-askhow-white hover:bg-askhow-primary-500/75 transition-colors cursor-pointer disabled:cursor-not-allowed disabled:bg-askhow-black-04;
  }
}

* {
  color: #2B2B3E;
}

#root {
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.not-scroll {
  height: 100%;
  position: fixed;
  width: 100%;
}

input:focus, textarea:focus, select:focus {
  outline:none;
}
.overflow-x-overlay { overflow-x: overlay; }

.overflow-x-overlay::-webkit-scrollbar {
    width: 4px;
}

.overflow-x-overlay::-webkit-scrollbar-thumb {
    background-color: #B2B2C8;
    border-radius: 100px;
}